var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row" },
        [
          _c("statistics-slider", {
            attrs: { "resource-type": "app", "resource-uuid": _vm.resourceUuid }
          })
        ],
        1
      ),
      _c("menu-card", {
        staticClass: "mb-base",
        attrs: { identifier: this.$route.params.uuid, pannels: this.pannels }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }