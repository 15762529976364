import AppsService from '@/modules/Apps/Services/AppsService.js'

export let appsActions = {
    updateapp: {
        title: 'apps.update.title',
        drm: [
          { context: 'apps', drm: 'dataapp' }
        ],
        action: async (uuid) => {
          window.dispatchEvent(new CustomEvent('showSpinner'))
          let event;
          try {
            await AppsService.updateApp(uuid)
            event = new CustomEvent('notifySuccess', {
              detail: {
                title: 'Success',
                message: 'App database update in progress...'
              }
            });
          } catch (e) {
            event = new CustomEvent('notifyError', {
              detail: {
                title: 'Error',
                message: 'An error occurred while trying to update the App database'
              }
            });
          }

          window.dispatchEvent(new CustomEvent('hideSpinner'))
          window.dispatchEvent(event)
        },
    }
}
