<template>
  <div>
    <div class="vx-row">
      <statistics-slider resource-type="app" :resource-uuid="resourceUuid" />
    </div>

    <menu-card
      class="mb-base"
      :identifier="this.$route.params.uuid"
      :pannels="this.pannels"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import MenuCard from "@/modules/Shared/Components/menu-card/MenuCard.vue";
import StatisticsSlider from "@/modules/Statistics/Components/StatisticsSlider";
import appMenuItems from "./appMenuItems.js";
import { generateMenu } from "@/modules/Shared/Helpers/menuHelper.js";
import AppsService from "@/modules/Apps/Services/AppsService";

export default {
  components: {
    StatisticsSlider,
    MenuCard,
  },
  data() {
    return {
      pannels: [],
    }
  },
  computed: {
    resourceUuid() {
      return this.$route.params.uuid;
    },
  },
  methods: {
    ...mapMutations( ['UPDATE_APPLICATION_UUID']),
  },
  mounted() {
    this.UPDATE_APPLICATION_UUID(this.$route.params.uuid);
  },
  async created() {
    const appBasicInfo = await AppsService.basicAppInfo(this.$route.params.uuid)
    const features = appBasicInfo.data.object.features.map(feature => feature.key)
    this.pannels = generateMenu(appMenuItems, this.$route.params.uuid, features)
  }
};
</script>
